<template>
	<div :class="containerClass">

    <transition name="layout-menu-container" v-if="mudarLayout()">
        <div class="layout-menu-container">
          <div class="layout-menu-wrapper">
            <div class="menu-scroll-content">
              <TreeTable
                ref="tree123"
                :value="hierarquiaEscritoPrincipal"
                :lazy="true"
                :scrollable="true"
                :expandedKeys="chavesExpandidas"
                :loading="exibirCarregando"
                selectionMode="single"
                :selectionKeys="selectedTreeItem"
                @node-select="onSelectItemTree"
                @node-unselect="onUnselectItemTree"
                scrollHeight="90vh"
                style="min-height: 90vh"
              >
                <Column :expander="true"
                >
                  <template #header>
                    <div class="col-12 flex justify-content-start mb-0 mt-0" v-if="escritoAtual">
                      <img
                        :src="$imagemEscrito(escritoAtual.tipoEscrito,
                        escritoAtual.tipoEscrito=='PROCESSO'? escritoAtual.eletronicoProcesso: false)"
                        width="25" height="25"/>
                      &nbsp;&nbsp;
                      <div>
                        <h4 class="mb-0" v-if="escritoAtual.tipoEscrito=='PROCESSO'">
                            {{ (escritoAtual.eletronicoProcesso? "Processo Eletrônico N° ": "Processo Físico N°") + escritoAtual.codigo}}
                        </h4>
                        <h4 v-if="escritoAtual.tipoEscrito=='MANIFESTACAO'" class="mb-0">
                            {{ "Manifestação N° " + escritoAtual.codigo}}
                        </h4>
                        <h4 v-if="escritoAtual.tipoEscrito=='DOCUMENTO' || escritoAtual.tipoEscrito=='RASCUNHO'" class="mb-0">
                            {{ (escritoAtual.tipoEscrito=="DOCUMENTO"? "Documento N° ": "Rascunho N° ") + escritoAtual.codigo}}
                        </h4>
                        <div style="text-decoration: underline;" v-tooltip.bottom="getTipoVisualizacaoTooltip()">
                          {{ getTipoVisualizacao() }}
                        </div>
                      </div>
                      <Button v-show="this.usuario.email" icon="pi pi-check-square" @click="marcarDocumentosDownload"
                        class="p-button-lg p-button-rounded p-button-text"
                        v-tooltip.bottom="'Selecionar Documentos para Download'"/>
                      <Button icon="pi pi-download" @click="downloadListaArquivoAviso"
                          class="p-button-lg p-button-rounded p-button-text p-button-danger"
                          v-tooltip.bottom="'Baixar Lista de Documentos Selecionados'"
                          v-show="multiDownload"/>
                    </div>
                  </template>

                  <template #body="slotProps">
                    <Checkbox class="mr-1" v-show="multiDownload" :value="infoDownload(slotProps.node.data.infoArquivoDownload)" v-model="escritosSelecionados"/>
                    <span v-tooltip.bottom="slotProps.node.data.toolTip">
                      {{ slotProps.node.data.descricao }}
                      <Button icon="pi pi-check-square" @click="marcarTodosDocumentosDownload(slotProps.node.data)"
                            class="p-button-lg p-button-rounded p-button-text p-button-danger"
                            v-tooltip="'Selecionar Árvore de Documentos'"
                            v-model="hierarquiaEscritoAtual"
                            v-if="slotProps.node.data.listaHierarquia.length > 0 && multiDownload"/>
                    </span>
                  </template>
                </Column>
              </TreeTable>
              <div class="ml-2 mt-2">
                <i class="pi pi-circle-on" style="font-Size: 1.2rem;color: blue;  margin-left:0rem !important;"></i>
                <b class="ml-1" style="font-size:13px;font-weight:initial">Restaurado</b>
                &nbsp;
                <i class="pi pi-circle-on ml-0" style="fontSize: 1.2rem;color: orange"></i>
                <b class="ml-1" style="font-size:13px;font-weight:initial">Não Restaurado</b>
                &nbsp;
                <i class="pi pi-circle-on ml-0" style="fontSize: 1.2rem;color: red"></i>
                <b class="ml-1" style="font-size:13px;font-weight:initial">Com Falha</b>
                &nbsp;
                <i class="pi pi-circle-on ml-0" style="fontSize: 1.2rem;color: green"></i>
                <b class="ml-1" style="font-size:13px;font-weight:initial">Sem Efeito</b>
                <!-- &nbsp;
                <i class="pi pi-circle-on ml-0" style="fontSize: 1.2rem;color: black"></i>
                <b class="ml-1" style="font-size:13px;font-weight: bold">Documento Interno</b> -->
              </div>
              <div class="flex justify-content-end mt-3">
                <h6>{{ usuario.sistemaOrigem + " - " + usuario.nome + " - v" + version}}</h6>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="layout-main" :style="{'margin-left': mainMarginLeftWidth}">
        <div class="layout-content">
          <TabView
              class="tabview"
              @tab-change ="changeTabView"
              :activeIndex="tvViewIndex">
            <!-- VISUALIZACAO -->
            <TabPanel>
                <template #header>
                <div>
                  <i class="fas fa-search mr-2"></i>
                  <span class="texto-tabview" style="width:100px;">Visualização</span>
                </div>
                </template>
            </TabPanel>
            <!-- DETALHES -->
            <TabPanel :disabled="!hierarquiaEscritoPrincipal">
              <template #header>
                <div>
                  <i class="fas fa-bars mr-2"></i>
                  <span class="texto-tabview">Detalhes</span>
                </div>
              </template>
            </TabPanel>
            <!-- LOG -->
            <TabPanel v-if="!usuarioExterno()" :disabled="!(hierarquiaEscritoAtual &&hierarquiaEscritoAtual.tipoEscrito=='DOCUMENTO')">
              <template #header>
                <div>
                  <i class="fas fa-history mr-2"></i>
                  <span class="texto-tabview">Log</span>
                </div>
              </template>
            </TabPanel>
          </TabView>
          <div class="mr-3 ml-3">
              <!-- VISUALIZACAO INICIO -->
              <div v-if="tvViewIndex==0">
                  <div v-html="tresUltimosLogsEscritoAtual" style="color: red; margin-top:-1.5rem;"
                    class="flex justify-content-end mr-3 ml-3"
                    v-if="hierarquiaEscritoAtual && (hierarquiaEscritoAtual.tipoEscrito=='DOCUMENTO')"/>
                  <div class="fluid formgrid grid col-12" v-if="hierarquiaEscritoAtual">
                    <div class="col-1 flex md:col-1 justify-content-start">
                        <Button icon="pi pi-window-maximize" @click="maximizeView"
                        class="p-button-lg p-button-rounded p-button-text" v-tooltip.bottom="'Maximizar'" v-if="!maximize && showTree"/>
                        <Button icon="pi pi-window-minimize" @click="minimizeView"
                        class="p-button-lg p-button-rounded p-button-text" v-tooltip.bottom="'Minimizar'" v-if="maximize  && showTree"/>
                    </div>
                    <div class="col-1 flex md:col-1 justify-content-start">
                      <Dropdown @change="exibeArquivo()" v-model="visualizadorSelected" :options="visualizadorOpcoes" optionLabel="name" />
                    </div>
                    <div class="col-8 flex md-8 justify-content-center">
                      <Button icon="pi pi-step-backward" @click="getPrimeiraPagina"
                        class="p-button-lg p-button-rounded p-button-text" v-tooltip.bottom="'Primeira página'"/>
                        &nbsp;&nbsp;&nbsp;
                      <Button icon="pi pi-caret-left" @click="getPaginaAnterior"
                      class="p-button-lg p-button-rounded p-button-text" v-tooltip.bottom="'Página anterior'"/>
                        &nbsp;&nbsp;
                        <InputText
                          style="width: 65px;text-align:center;vertical-align:top;border: 0none;"
                          v-model="paginaAtual"
                          v-on:keydown.enter="goToPagina"
                          />
                        &nbsp;
                        <InputText
                          disabled
                          style="width:80px;color:black;text-align:center;vertical-align:top;border: 0 none;"
                          :value="'de   '+totalPaginas"
                        />
                        &nbsp;
                      <Button icon="pi pi-caret-right" @click="getProximaPagina"
                      class="p-button-lg p-button-rounded p-button-text" v-tooltip.bottom="'Próxima página'"/>
                        &nbsp;&nbsp;&nbsp;
                      <Button icon="pi pi-step-forward" @click="getUltimaPagina"
                      class="p-button-lg p-button-rounded p-button-text" v-tooltip.bottom="'Útlima página'"/>
                    </div>
                    <div class="col-2 flex md:col-2 justify-content-end">
                      <Button v-if="!usuarioExterno()" icon="pi pi-file" @click="gerarCapaProcesso"
                      class="p-button-lg p-button-rounded p-button-text"
                      v-tooltip.bottom="'Atualizar Capa'"/>
                        &nbsp;
                      <Button v-if="usuario.email" icon="pi pi-download" @click="downloadArquivoAviso"
                      class="p-button-lg p-button-rounded p-button-text"
                      v-tooltip.bottom="'Baixar Documento'"/>
                    </div>
                  </div>
                  <div class="col-12 flex justify-content-end" style="margin-top:-1.0rem; ">
                      <Button icon="pi pi-download" @click="downloadArquivoEdicao"
                        class="p-button-lg p-button-rounded p-button-text"
                        v-tooltip.bottom="'Baixar Documento Original'"
                        v-if="!hierarquiaEscritoAtual && (escritoAtual && escritoAtual.tipoEscrito=='RASCUNHO')"/>
                  </div>
                  <!-- PDF -->
                  <iframe v-if="visualizadorSelected.value == 1" id="frameViewEscrito" width="100%" style="height: 85vh; border: none;" />
                  <!-- <canvas v-if="visualizadorSelected.value == 2" id="the-canvas"></canvas> -->
                  <div v-if="visualizadorSelected.value == 2 || visualizadorSelected.value == 3" id="holder-pai">
                    <div v-if="ellipsTop" class="loader-ellips">
                      <span class="loader-ellips__dot"></span>
                      <span class="loader-ellips__dot"></span>
                      <span class="loader-ellips__dot"></span>
                      <span class="loader-ellips__dot"></span>
                    </div>
                    <div id="holder"></div>
                    <div v-if="ellipsBot" class="loader-ellips">
                      <span class="loader-ellips__dot"></span>
                      <span class="loader-ellips__dot"></span>
                      <span class="loader-ellips__dot"></span>
                      <span class="loader-ellips__dot"></span>
                    </div>
                  </div>
              </div>
              <!-- VISUALIZACAO FIM -->
              <!-- DETALHE INICIO -->
              <div  v-if="tvViewIndex==1">
                  <div v-html="tresUltimosLogsEscritoAtual" style="color: red; margin-top:-1.5rem;"
                  class="flex justify-content-end mr-3 ml-3"
                  v-if="hierarquiaEscritoAtual &&hierarquiaEscritoAtual.tipoEscrito=='DOCUMENTO'"/>
                  <detalhe-escrito v-if="hierarquiaEscritoAtual" v-bind:idEscrito="hierarquiaEscritoAtual.idEscrito" v-bind:externo="usuarioExterno()"/>
              </div>
              <!-- DETALHE FIM -->
              <!-- LOG INICIO -->
              <div  v-if="tvViewIndex==2">
                <div v-html="tresUltimosLogsEscritoAtual" style="color: red; margin-top:-1.5rem;"
                class="flex justify-content-end mr-3 ml-3"
                v-if="hierarquiaEscritoAtual &&hierarquiaEscritoAtual.tipoEscrito=='DOCUMENTO'"/>
                <div class="fluid formgrid grid col-12 mt-4" v-if="hierarquiaEscritoAtual &&hierarquiaEscritoAtual.tipoEscrito=='DOCUMENTO'">
                  <div class="field col-12 md:col-4">
                        <b>
                        <span>
                            {{ "DOCUMENTO N°: " + hierarquiaEscritoAtual.codigo}}
                        </span>
                        </b>
                    </div>
                  <div class="fluid formgrid grid col-12">
                      <div class="field col-12 md-12">
                          <span>
                              {{ "DESCRIÇÃO: "+ hierarquiaEscritoAtual.descricao.toUpperCase()}}
                          </span>
                      </div>
                  </div>
                  <Panel header="Log de Acesso" style="width:100%">
                    <div class="grid flex justify-content-start">
                      <div class="col-12 flex justify-content-start">
                        <span style="margin-top: 0.5rem">PERÍODO:&nbsp;&nbsp;</span>
                        <Calendar
                          :showIcon="true"
                          v-model="dataInicioPeriodo"
                          dateFormat="dd/mm/yy"
                          style="width:200px"
                          :showButtonBar="true"
                        />
                        <span style="margin-top: 0.5rem">&nbsp;&nbsp;À:&nbsp;&nbsp;</span>
                        <Calendar
                          :showIcon="true"
                          v-model="dataFimPeriodo"
                          style="width:200px"
                          dateFormat="dd/mm/yy"
                          :showButtonBar="true"
                        />
                        &nbsp;
                        <Button label="OK" @click="obterLogAcesso" style="width:6rem; align-text:right !important;"/>
                      </div>
                    </div>
                    <DataTable
                      :value="dataLogsDocumento"
                      :resizableColumns="true"
                      columnResizeMode="fit"
                      class="datatable-responsive mt-3 mb-2"
                      :lazy="true"
                      data-key="id"
                    >
                      <Column field="dataHoraInicial" header="Início">
                        <template #body="slotProps">
                          <span>{{ $formatarDataHora(slotProps.data.dataHoraInicial) }}</span>
                        </template>
                      </Column>
                      <Column field="dataHoraFinal" header="Término">
                        <template #body="slotProps">
                          <span>{{ $formatarDataHora(slotProps.data.dataHoraFinal) }}</span>
                        </template>
                      </Column>
                      <Column header="Usuário">
                        <template #body="slotProps">
                          <span v-tooltip.bottom="slotProps.data.usuarioDescricao"
                              v-if="!slotProps.data.cpfUsuarioExterno">{{ slotProps.data.usuarioDescricao }}</span>
                          <span v-tooltip.bottom="slotProps.data.observacao + '(' + slotProps.data.cpfUsuarioExterno + ')'"
                            v-if="slotProps.data.cpfUsuarioExterno">{{ slotProps.data.observacao + "(" + slotProps.data.cpfUsuarioExterno + ")" }}</span>
                        </template>
                      </Column>
                      <Column field="ip" header="IP" />
                      <template #empty>Nenhum registro encontrado :(</template>
                    </DataTable>
                  </Panel>
                </div>
              </div>
              <!-- LOG FIM-->
          </div>
      </div>
      <!-- <AppFooter/> -->
      </div>
  </div>
  <Loading/>
  <Dialog v-model:visible="showDialog" modal header="Alerta - Exibindo somente peças iniciais " :style="{ width: '30vw' }">
    <p style="font-weight:bold;" align="center">Você deve ser um dos interessados no processo para ter acesso completo aos autos. <br> Solicite a habilitação e acesso aos autos através do envio de um protocolo, via DEC, com o assunto: SOLICITAÇÕES
      / HABILITAÇÃO E ACESSO AOS AUTOS.</p>
    <p style="font-weight:bold;" align="center"> Domicílio Eletrônico de Contas - DEC <br> <a href="https://dec.tce.am.gov.br" target="_blank">https://dec.tce.am.gov.br</a></p>
  </Dialog>
</template>


<script>
import Loading from "@/components/Loading.vue";
import SpedeService from "@/service/SpedeService";
import mimeType from "@/data/mimeType.js";
import { showError, showSucess } from "@/global";
import DetalheEscrito from "@/components/DetalheEscrito";
import TipoArquivo from "@/data/tipoArquivo.js";
import pjson from "@/../package.json";

export default {
  components: {
    DetalheEscrito, Loading
  },
  data() {
    return {
      outroItem: false,
      TipoArquivo: TipoArquivo,
      mimeType: mimeType,
      dataInicioPeriodo: null,
      dataFimPeriodo: null,
      dataLogsDocumento: null,
      classDivView: "field col-12 md-12",
      splitTreeKey: 1,
      selectedTreeItem: {},
      hierarquiaEscritoAtual: null,
      paginaEscritoAtual: null,
      paginaAtual: 1,
      totalPaginas: 0,
      showTree: false,
      maximize: false,
      idEscritoUltimoLog: -1,
      tresUltimosLogsEscritoAtual: "",
      splitView: 0,
      exibirCarregando: false,
      exibirVariosArquivos: false,
      hierarquiaEscritoPrincipal: null,
      listaPaginacao: [],
      chavesExpandidas: {},
      versaoItemDocumento: -1,
      exibirArquivo: false,
      exibirImagem: false,
      idItem: 0,
      tipoArquivoItem: "NAO_DEFINIDO",
      tipoview: null,
			numero: 0,
			ano: 0,
			id: 0,
			tipoArquivo: 0,
			versao: 0,
      listaMidiasFisicas: [],
      titulo: "",
      isProcesso: false,
      txtProcessoFisico: "",
      tvViewKey: 1,
      tvViewIndex: 0,
      tvViewIndexUpdate: -1,
      spedeService: null,
      loadingLog:false,
      escritoAtual: null,
      idEscritoAtual: 0,
      tramitacaoMaisRecente: null,
      multiDownload: false,
      isCheckAll: false,
      escritosSelecionados: [],
      escritosSelecionadosOrdenado: [],
      tipoOperacao: null,
      usuario: {},

      layoutMode: 'static',
			staticMenuDesktopInactive: false,
			staticMenuMobileActive: false,
			overlayMenuActive: false,
			topbarMenuActive: false,
			activeTopbarItem: null,
			menuActive: false,
			darkMenu: true,
      mainMarginLeftWidth: '29vw',
      version: pjson.version,
      tipoVisualizacao: '',
      situacaoEscrito: '',
      visualizadorOpcoes: [
        {name: 'Modo de visualização - 1', value: 1},
        {name: 'Modo de visualização - 2', value: 2}
      ],
      visualizadorSelected: this.getModoVisualizacao() ? JSON.parse(this.getModoVisualizacao()) : {name: 'Modo de visualização - 2', value: 2},
      listaPDFs: [],
      observerPageAtual: null,
      observerNewPage: null,
      pdfjsLib: null,
      ellipsBot: false,
      ellipsTop: false,
      scale: 1.5,
      entryAtual: null,
      downloadTimer: Date.now(),
      doc: 0,
      showDialog: false,
    };
  },
  created() {
    this.spedeService = new SpedeService();
    if (this.$route.query.tipoOperacao){
      this.tipoOperacao =  this.$route.query.tipoOperacao;
    }
    if (this.$route.query.tipoview){
        this.tipoview = this.$route.query.tipoview;
        //console.log("this.tipoview: " + this.tipoview);
    }
    if (this.$route.query.numero){
        this.numero = this.$route.query.numero;
    }
    if (this.$route.query.ano){
        this.ano = this.$route.query.ano;
    }
    if (this.$route.query.id){
        this.idEscritoAtual = this.id = this.$route.query.id;
        //console.log("this.idEscritoAtual = this.id: " + this.id);
    }
    if (this.$route.query.tipoarquivo){
        this.tipoArquivo = this.$route.query.tipoarquivo;
    }
    if (this.$route.query.versao){
        this.versao = this.$route.query.versao;
    }
    if (this.$route.query.doc){
        this.doc = this.$route.query.doc;
    }
    this.verificaTipoVisualizacao();
    if (this.$route.query.sessionId) {
      this.spedeService.getRecuperarInfoUsuario().then(data => {
        this.usuario = data;
      })
    }
    this.observerPageAtual = new IntersectionObserver(this.onPageObserver,{threshold: 0.4});
    this.observerNewPage = new IntersectionObserver(this.onNewPageObserver,{threshold: 0.01});
    this.pdfjsLib = window['pdfjs-dist/build/pdf'];
  },
  computed:{
    containerClass() {
			return ['layout-wrapper', {
				'layout-horizontal': this.layoutMode === 'horizontal',
				'layout-overlay': this.layoutMode === 'overlay',
				'layout-static': this.layoutMode === 'static',
				'layout-slim': this.layoutMode === 'slim',
				'layout-static-inactive': this.staticMenuDesktopInactive,
				'layout-mobile-active': this.staticMenuMobileActive,
				'layout-overlay-active': this.overlayMenuActive,
				'layout-menu-dark': !this.darkMenu,
				'layout-menu-light':!this.darkMenu,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
			}];
    }
  },
  mounted() {
    //console.log('mounted')
  },
  watch: {
    visualizadorSelected() {
      this.setModoVisualizacao(JSON.stringify(this.visualizadorSelected));
    },
    paginaAtual() {
      this.selecionarArvore();
    },
    tipoVisualizacao() {
      this.showDialog = this.usuario.sistemaOrigem == "DEC" && this.tipoVisualizacao == 'PECA_INICIAL'? true : false;
    }
  },
  methods: {
    onPageObserver(entries) {
      entries.map((entry) => {
        if(entry.isIntersecting) {
          let proximaPagina = this.contarAtePagina(entry.target.indice) + entry.target.pagina;
          this.paginaAtual = proximaPagina;
          this.entryAtual = entry.target;
          this.paginaEscritoAtual = this.recuperarPaginaSelecionada(proximaPagina);
        }
      });
    },
    onNewPageObserver(entries) {
      entries.map((entry) => {
        if(!entry.isIntersecting) return

        let container = Array.from(document.getElementById('holder').childNodes);
        let entryIndex = container.findIndex(x => x == entry.target)
        let midSegmentIndex = Math.floor(container.length / 2);
        let item = null;
        let proximo = true;
        //console.log('entryIndex', [entryIndex])
        //console.log('midSegmentIndex', [midSegmentIndex])

        if(entryIndex > midSegmentIndex) {
          if(this.paginaAtual+1 == this.totalPaginas) return; // Pagina final

          proximo = true;
          item = this.getItem(container[container.length-1], proximo);
        }
        else
        if(entryIndex < midSegmentIndex) {
          if(this.paginaAtual-1 == 1) return; // Pagina inicial

          proximo = false;
          item = this.getItem(container[0], proximo);
        }
        if(item && item.negarAcessoDescricao) {
          showError(item.negarAcessoDescricao);
          return;
        }
        if(item && (!item.param || item.param.length < 20 )) {
          showError("Usuário não possui permissão para visualizar o arquivo!!");
          return;
        }
        if(item && item.indice != -1) {
          if(container.some(x => x.id == item.idEscrito && x.pagina == item.pagina)) {
            console.log('duplicada!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
            // já esta no container
            return;
          }
          if(proximo) {
            this.ellipsBot = true;
          } else {
            this.ellipsTop = true;
          }
          this.novaPaginaPDF(item, proximo);
        }
      })
    },
    obterLogAcesso(){
      this.loadingLog = false;
      this.spedeService.getLogsDocumento(this.hierarquiaEscritoAtual.idEscrito, this.$formatarDataFromCalendar(this.dataInicioPeriodo), this.$formatarDataFromCalendar(this.dataFimPeriodo))
      .then((data) => {
        //console.log(data);
        this.dataLogsDocumento = data;
        this.loadingLog = true;
      });
    },
    maximizeView(){
        this.classDivView = "field col-12 md-12";
        this.maximize = true;
    },
    minimizeView(){
        this.classDivView = "field col-12 md-8";
        this.maximize = false;
    },
    goToPagina(){
      this.getPagina(this.paginaAtual);
    },
		getPrimeiraPagina(){
			this.getPagina(1);
		},
		getUltimaPagina(){
			this.getPagina(this.totalPaginas);
		},
		getProximaPagina(){
      if(this.visualizadorSelected.value == 2) {
        let container = Array.from(document.getElementById('holder').childNodes);
        let entryIndex = container.findIndex(x => x == this.entryAtual)
        let proximo = container[entryIndex+1];
        if(proximo) {
          proximo.scrollIntoView();
        }
      } else {
        this.paginaAtual = Number(this.paginaAtual);
        this.getPagina(this.paginaAtual+1);
      }
		},
		getPaginaAnterior(){
      if(this.visualizadorSelected.value == 2) {
        let container = Array.from(document.getElementById('holder').childNodes);
        let entryIndex = container.findIndex(x => x == this.entryAtual)
        let anterior = container[entryIndex-1];
        if(anterior) {
          anterior.scrollIntoView();
        }
      } else {
        this.paginaAtual = Number(this.paginaAtual);
        this.getPagina(this.paginaAtual-1);
      }
		},
    onSelectItemTree(event){
      if (event.data.paginaInicial > 0){
        this.hierarquiaEscritoAtual = event.data;
        this.openDoc(this.hierarquiaEscritoAtual);
      }
      this.selecionarArvore();
    },
    onUnselectItemTree(event){
      event.stopPropagation;
    },
    verificaTipoVisualizacao(){
				if (this.numero>0 && this.ano>0){
          this.visualizarProcesso(this.numero, this.ano);
          return;
				}
        if (this.tipoview != null){
          switch(this.tipoview){
            case "escrito":
                if (this.id>0){//11111
                  this.visualizarArquivosEscrito(this.id);
                  /*
                  this.spedeService.getEscrito(this.id).then((data) => {
                    this.escritoAtual = data;
                    this.idEscritoAtual = data.idEscrito;
                    if (this.escritoAtual!=null){
                      this.visualizarArquivosEscrito(this.escritoAtual);
                    }
                  });
                  */
                }
                break;
            case "itemprotocolo":
                if (this.id>0){
                  this.visualizarOutroTipoArquivo(this.id, TipoArquivo.ITEM_PROTOCOLO);
                }
                break;

            case "imagem":
                if (this.id>0){
                  this.visualizarImagem(this.id, this.tipoArquivo);
                }
                break;
            case "outros":
                if (this.id>0){
                  this.visualizarOutroTipoArquivo(this.id, this.tipoArquivo, this.versao);
                }
                break;
          }
        }
    },
		visualizarProcesso(numero, ano){
      this.spedeService
        .getProcesso(numero,ano)
        .then((data) => {
            if (data){
              this.visualizarArquivosEscrito(data);
            }
            else{
              showError("Processo informado não existe!");
            }
        });
		},
    atualizarEscritoAtual(escrito){
      this.escritoAtual = escrito;
        this.idEscritoAtual = this.escritoAtual.idEscrito;
        switch(this.escritoAtual.tipoEscrito){
            case "DOCUMENTO":
              this.titulo = "Documento: " + this.escritoAtual.codigo;
              break;
            case "PROCESSO":
              this.titulo = "Processo: " + this.escritoAtual.codigo;
              this.isProcesso = true;
              this.txtProcessoFisico = this.escritoAtual.eletronicoProcesso? "": "Este processo não é eletrônico. Para ter acesso ao conteúdo integral consulte o processo físico. ";
              if(!this.usuarioExterno()) {
                this.spedeService.getMidiasFisicas(this.idEscritoAtual).then((data) => {
                  if (data && data.length > 0){
                      this.listaMidiasFisicas = data;
                  }
                });
              }
              break;
            case "MANIFESTACAO":
              this.titulo = "Manifestação: " + this.escritoAtual.codigo;
        }
    },
		visualizarArquivosEscrito(idEscrito){
        this.exibirCarregando = true;
        this.$store.state.dlgLoading = true;
        this.spedeService.getHierarquiaPaginacaoEscrito(idEscrito).
            then((data) => {
              this.hierarquiaEscritoPrincipal = Object.freeze(data.listaHierarquiaPrincipal);
              this.listaPaginacao = Object.freeze(data.listaPaginacao);
              //this.hierarquiaEscritoPrincipal = [this.listaPaginacao[0].node];
              //console.log('listaPaginacao:', this.listaPaginacao)
              //console.log('hierarquiaEscritoPrincipal', this.hierarquiaEscritoPrincipal)
              this.tipoVisualizacao = this.hierarquiaEscritoPrincipal[0].data.tipoVisualizacao;
              this.situacaoEscrito = this.hierarquiaEscritoPrincipal[0].data.situacaoEscrito ? this.hierarquiaEscritoPrincipal[0].data.situacaoEscrito : '';
              this.exibirCarregando = false;
              this.$store.state.dlgLoading = false;
              this.atualizarEscritoAtual(this.hierarquiaEscritoPrincipal[0].data);
              this.expandAll();
              this.exibirVariosArquivos = true;
              this.initViewArquivosEscrito();
        })
        .catch(error => {
          showError(error);
          this.$store.state.dlgLoading = false;
        });
		},
		initViewArquivosEscrito(){
      this.openDoc(this.hierarquiaEscritoPrincipal);
      this.totalPaginas = this.verificarTotalDePaginas(); //this.listaPaginacao.length;

      let pagina = 1;
      // Direciona para o documento informado
      if(this.doc > 0) {
        let item = this.listaPaginacao.find(x => x.idEscrito == this.doc);
        if(item) {
          pagina = item.hierarquiaEscrito.paginaInicial
        }
      }
			this.getPagina(pagina);
			if (this.hierarquiaEscritoPrincipal &&
          this.hierarquiaEscritoPrincipal.length > 0 &&
          this.hierarquiaEscritoPrincipal[0].children.length > 0){
				this.showTree = true;
        this.classDivView = "field col-12 md-8";
			}
		},
    verificarTotalDePaginas(){
      let total = 0;
      this.listaPaginacao.forEach(x => {
        total += x.totalPaginas;
      })
      return total;
    },
		openDoc(hierarquia){
			this.getPagina(hierarquia.paginaInicial);
		},
    recuperarPaginaSelecionada(paginaSelecionada){
      //console.log('paginaSelecionada', paginaSelecionada)
      let contador = 0;
      let paginaIndice = 0;
      let retorno = null;

      for (let p of this.listaPaginacao) {
        retorno = p;
        if((contador + p.totalPaginas) >= paginaSelecionada && p.totalPaginas > 1){
          paginaIndice = paginaSelecionada - contador;
          contador += paginaIndice;
          // Ajustar parametrosExibirArquivo com a página correta
          retorno.parametrosExibirArquivo = retorno.parametrosExibirArquivo.substring(0, retorno.parametrosExibirArquivo.indexOf("/pg/")) + '/pg/'+paginaIndice
          retorno.pagina = paginaIndice;
          retorno.totalPaginas = p.totalPaginas;
          break;
        } else {
          contador += p.totalPaginas;
        }
        if(contador == paginaSelecionada) {
          break;
        }
      }
      return retorno;
    },
		getPagina(pagina){
				if (pagina <= 0) pagina = 1;
				if (pagina > this.totalPaginas) pagina = this.totalPaginas;
				if ((pagina > 0) && (pagina <= this.totalPaginas)){
					let paginaEscrito =  this.recuperarPaginaSelecionada(pagina); //this.listaPaginacao[paginas-1];

          if(paginaEscrito.negarAcessoDescricao) {
            showError(paginaEscrito.negarAcessoDescricao);
            return;
          }
					if (!paginaEscrito.permiteVisualizar){
            showError("Usuário não possui permissão para visualizar o arquivo!");
            return;
          }
          else{
            this.paginaAtual = pagina;
						this.paginaEscritoAtual =  paginaEscrito;
            if (this.paginaEscritoAtual.hierarquiaEscrito.tipoEscrito=="DOCUMENTO"){
							if (this.paginaEscritoAtual.idEscrito != this.idEscritoUltimoLog){
								//Recupera log
                this.recuperarLog();
							}
						}
						else{
							this.tresUltimosLogsEscritoAtual = "";
						}

            if (this.tvViewIndex == 0){
              this.exibeArquivo();
            }
					}
				}
		},
    recuperarLog() {
      if(!this.usuarioExterno()) {
        this.spedeService.getRecuperarLog(this.paginaEscritoAtual.idEscrito).
        then((data) => {
          if (data){
            this.tresUltimosLogsEscritoAtual = data;
            //console.log('log', data)
          }
        });
        this.idEscritoUltimoLog = this.paginaEscritoAtual.idEscrito;
      }
    },
    gerarCapaProcesso(){
      this.$store.state.dlgLoading = true;
      this.spedeService
        .getGerarCapaProcesso(this.idEscritoAtual)
        .then(() => {
            this.getPrimeiraPagina();
            this.$store.state.dlgLoading = false;
        });
    },
    marcarPecas(listaHierarquia){
      for (var itemEscrito of listaHierarquia) {
          if ( this.escritosSelecionados.indexOf(itemEscrito.idEscrito) > -1) {
            itemEscrito.semEfeito = true;
          }
          if(itemEscrito.listaHierarquia.length >0){
            this.marcarPecas(itemEscrito.listaHierarquia);
          }
      }
    },
    downloadArquivoAviso() {
      let diff = this.downloadTimer - Date.now();
      let seconds = Math.round(Math.abs(diff/1000))
      if(seconds <= 5) {
        showError("Aguarde "+ (5 - seconds) + " segundos!!");
        return;
      }
      this.downloadTimer = Date.now()
      this.downloadArquivo();
      /*
      if (this.showTree)
        this.downloadArquivo();
      else
        this.downloadArquivoEdicao();
      */
    },
    downloadListaArquivoAviso(){
      if(this.escritosSelecionados == 0) {
        showError('Selecione pelo menos um item da lista!')
        return;
      }
      let diff = this.downloadTimer - Date.now();
      let seconds = Math.round(Math.abs(diff/1000))
      if(seconds <= 60) {
        showError("Aguarde "+ (60 - seconds) + " segundos!");
        return;
      }
      this.downloadTimer = Date.now()
      this.$confirm.require({
                      message: 'Será registrado no histórico deste documento o seu download. Confirma o download?',
                      header: 'Aviso',
                      acceptLabel: 'Sim',
                      rejectLabel: 'Não',
                      icon: 'pi pi-exclamation-triangle',
                      accept: () => {
                          this.escritosSelecionadosOrdenado = [];
                          this.ordenarLista(this.hierarquiaEscritoPrincipal[0].data);
                          this.downloadListaArquivo();
                      },
                      reject: () => {
                      }
      });
    },
    ordenarLista(pai) {
      //console.log('pai', pai)
      let elemento = this.escritosSelecionados.find(element => element && (element.id == pai.idEscrito) && (element.idPai == pai.idEscritoPai))
      if(elemento) {
        // Ignorar !permiteVisualizar && negarAcessoDescricao
        if(!this.listaPaginacao.find(x => (x.idEscrito == elemento.id) && (!x.permiteVisualizar || x.negarAcessoDescricao))) {
          if(!this.escritosSelecionadosOrdenado.find(x => (x.id == elemento.id) && (x.idPai == elemento.idPai))) {
            this.escritosSelecionadosOrdenado.push(elemento)
          }
        }
      }
      pai.listaHierarquia.forEach(element => {
        this.ordenarLista(element);
      })
    },
    marcarDocumentosDownload() {
      this.multiDownload = !this.multiDownload;
    },
    marcarTodosDocumentosDownload(registro){
      this.isCheckAll = !this.escritosSelecionados.find(element => element != null && element.id == registro.idEscrito && element.idPai == registro.idEscritoPai);
      if(this.isCheckAll) {
        this.escritosSelecionados.push(registro.infoArquivoDownload);
        for(var key in registro.listaHierarquia) {
          this.marcarTodosDocumentosDownload(registro.listaHierarquia[key]);
        }
      }
      else {
        this.removerDocumentosSelecionados(registro);
      }
    },
    removerDocumentosSelecionados(registro) {
        const objeto = this.escritosSelecionados.find(element => element != null && element.id == registro.idEscrito && element.idPai == registro.idEscritoPai)
        const index  = this.escritosSelecionados.indexOf(objeto);
        if (index > -1) {
          this.escritosSelecionados.splice(index, 1);
        }
        if(registro.listaHierarquia.length > 0) {
          for(var key2 in registro.listaHierarquia) {
            this.removerDocumentosSelecionados(registro.listaHierarquia[key2])
          }
        }
    },
    updateCheckAll(){
      if(this.escritosSelecionados.length == this.hierarquiaEscritoPrincipal.length){
         this.isCheckAll = true;
      }else{
         this.isCheckAll = false;
      }
    },
    downloadListaArquivo(){
      this.$store.state.dlgLoading = true;
      let idDocs = [];
      let idCapa;

      this.escritosSelecionadosOrdenado.forEach(x => {
        if(x.tipo == 1){
          idDocs.push(x.id);
        }
        if(x.tipo == 4){
          idCapa = x.id;
        }
      })

      let parametros = {
        email: this.usuario.email,
        capa: idCapa,
        documentos: idDocs,
        subject: 'Processo ' + this.escritoAtual.codigo,
        descricao: 'Download dos documentos selecionados do Processo: ' + this.escritoAtual.codigo
      }

      this.spedeService
        .postObterArquivoPDFZip(parametros)
        .then((/*data*/) => {
            this.$store.state.dlgLoading = false;
            this.marcarDocumentosDownload();
            this.escritosSelecionados = [];
            this.escritosSelecionadosOrdenado = []
            //console.log('https://spede-pdf.tce.am.gov.br/status/'+data.requisicao)
            showSucess("O endereço do download será encaminhado para o e-mail: " + this.usuario.email);
        }).catch((data) => {
            let msg = "Falha ao baixar documento zip. " + data;
            if(data && data.response && data.response.data) {
              msg = data.response.data
            }
            this.$store.state.dlgLoading = false;
            showError(msg);
        });
    },
    downloadStatus(id) {
      var url = "https://spede-pdf.tce.am.gov.br/status/" + id;

      var xhttp = new XMLHttpRequest();
      let retorno = {
        status: '',
        statusMessage: ''
      };
      xhttp.open("GET", url, false);

      xhttp.onreadystatechange = function(){
          if (xhttp.readyState == 4 && xhttp.status == 200) {
              if(JSON.parse(xhttp.response).progresso == 1 && (JSON.parse(xhttp.response).status == "SUCESSO" || JSON.parse(xhttp.response).status == "ERRO")) {
                retorno.status = JSON.parse(xhttp.response).status;
                retorno.statusMessage = JSON.parse(xhttp.response).statusMessage;
              }
          }
      }
      xhttp.send();

      return retorno;
    },
    downloadArquivo(){
      this.$store.state.dlgLoading = true;
      let parametros = {
        email: "",
        descricao: ""
      }

      let idEscrito;
      let tipoDocumento;
      if(this.paginaEscritoAtual) {
        idEscrito = this.paginaEscritoAtual.idEscrito;
        tipoDocumento = this.paginaEscritoAtual.tipoArquivo;
      } else {
        idEscrito = this.escritoAtual.idEscrito
        tipoDocumento = this.escritoAtual.tipoEscrito
      }

      if(tipoDocumento == "DOCUMENTO" || tipoDocumento == "RASCUNHO"){
        parametros['documento'] = idEscrito;
      } else {
        parametros['capa'] = idEscrito;
      }

      this.spedeService
        .postObterArquivoPDF(parametros)
        .then((res) => {
            let tempoMaximo = 600 //10min
            let x  = setInterval(() => {
              let response = this.downloadStatus(res.requisicao);
              let bool = response.status == 'SUCESSO' ? true : false;
              tempoMaximo--;
              if(bool) {
                clearInterval(x);
                let tab = window.open("https://spede-pdf.tce.am.gov.br/download/" + res.requisicao, "_blank");
                if (tab == null || typeof(tab)=='undefined') {
                    alert('Por favor, desabilite o bloqueador de pop-ups!');
                }
                this.$store.state.dlgLoading = false;
              }
              if(tempoMaximo <= 0){
                clearInterval(x);
                showError("Falha ao baixar documento! Tempo Máximo Esgotado: " + (tempoMaximo/60) + "min");
                this.$store.state.dlgLoading = false;
              }
              if(response.status == 'ERRO') {
                clearInterval(x);
                showError("ERRO! - Spede-PDF:" + response.statusMessage);
                this.$store.state.dlgLoading = false;
              }
            }, 1000);
        }).catch((data) => {
            let msg = "Falha ao baixar documento. " + data;
            if(data && data.response && data.response.data) {
              msg = data.response.data
            }
            this.$store.state.dlgLoading = false;
            showError(msg);
        });
    },
    downloadArquivoEdicao(){
      this.$store.state.dlgLoading = true;
      this.spedeService
        .getArquivoExibir(this.escritoAtual.parametrosDownloadArquivo)
        .then((res) => {
            //console.log(res);
            const blob = new Blob([res.data], {type: res.headers["content-type"]});
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = window.URL.createObjectURL(blob);
                a.target = "_blank"
                a.download = "ARQUIVO_" + this.escritoAtual.tipoEscrito +"_" + this.escritoAtual.codigo +"."+ mimeType[res.headers["content-type"]].ext;
                a.click();
            }
            else {
                var fileURL = window.URL.createObjectURL(blob);
                let tab = window.open(fileURL, "_blank");
                if (tab == null || typeof(tab)=='undefined') {
                    alert('Por favor, desabilite o bloqueador de pop-up!');
                }
                else {
                    //tab.focus();
                    tab.location.href = fileURL;
                }
            }
            this.$store.state.dlgLoading = false;
        }).catch(() => {
            this.$store.state.dlgLoading = false;
            showError("Falha ao realizar operação");
        });
    },
    downloadArquivo1(){
        //console.log("downloadArquivo1");
        this.$store.state.dlgLoading = true;
        this.spedeService
          .getArquivoExibir(this.paginaEscritoAtual.hierarquiaEscrito.parametrosDownloadArquivo)
          .then((res) => {
              //console.log(res);
              const blob = new Blob([res.data], {type: res.headers["content-type"]});
              //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  var a = document.createElement("a");
                  document.body.appendChild(a);
                  a.href = window.URL.createObjectURL(blob);
                  a.target = "_blank"
                  a.download = "ARQUIVO_" + this.paginaEscritoAtual.hierarquiaEscrito.tipoEscrito +"_" + this.paginaEscritoAtual.hierarquiaEscrito.codigoEscrito +"."+ mimeType[res.headers["content-type"]].ext;
                  //a.click();
              /*}
              else {
                  var fileURL = window.URL.createObjectURL(blob);
                  let tab = window.open(fileURL, "_blank");
                  tab.location.href = fileURL;
              } */
              this.$store.state.dlgLoading = false;
          });
    },
    addPaginaToList(lista, idEscrito, pagina, param, centro, indice) {
			if(lista.some(x=> x.idEscrito == idEscrito && x.pagina == pagina)) {
				//console.log('ja existe')
				return lista;
			}
			lista.push({
        indice: indice,
				idEscrito: idEscrito,
				pagina: pagina,
        param: param,
        centro: centro
			})
			return lista;
		},
    ignorarPaginasTop(indice){
      // Com negarAcessoDescricao ou sem permissão de visualização
      let retorno = indice
      if(this.listaPaginacao[indice] && (this.listaPaginacao[indice].negarAcessoDescricao ||
        !this.listaPaginacao[indice].permiteVisualizar)) {
        if(this.listaPaginacao[indice].negarAcessoDescricao)
          showError(this.listaPaginacao[indice].negarAcessoDescricao)
        else
        if(!this.listaPaginacao[indice].permiteVisualizar)
          showError("Usuário não possui permissão para visualizar o arquivo!!!");
        retorno = this.ignorarPaginasTop(indice - 1);
      }
      return retorno;
    },
    retornarPaginaTop(indice, qntPaginasTop) {
			let retorno = [];
			if(qntPaginasTop > 0) {
				let lista = this.listaPaginacao;
				for(let i = indice; i > -1; i--){
          i = this.ignorarPaginasTop(i);
					for(let pagina = lista[i].totalPaginas; pagina >= 1; pagina--) {
						retorno.push({
              indice: i,
							idEscrito: lista[i].idEscrito,
							pagina: pagina,
              param: lista[i].parametrosExibirArquivo.replace(/\d+$/, pagina),
						});
						if(retorno.length >= qntPaginasTop) {
							break;
						}
					}
					if(retorno.length >= qntPaginasTop) {
						break;
					}
				}
			}
			return retorno
		},
    ignorarPaginasBot(indice){
      // Com negarAcessoDescricao ou sem permissão de visualização
      let retorno = indice
      if(this.listaPaginacao[indice] && (this.listaPaginacao[indice].negarAcessoDescricao ||
        !this.listaPaginacao[indice].permiteVisualizar)) {
        if(this.listaPaginacao[indice].negarAcessoDescricao)
          showError(this.listaPaginacao[indice].negarAcessoDescricao)
        else
        if(!this.listaPaginacao[indice].permiteVisualizar)
          showError("Usuário não possui permissão para visualizar o arquivo!!!");
        retorno = this.ignorarPaginasBot(indice + 1);
      }
      return retorno;
    },
		retornarPaginaBot(indice, qntPaginasBop) {
			let retorno = [];
			if(qntPaginasBop > 0) {
				let lista = this.listaPaginacao;
				for(let i = indice; i <= lista.length-1; i++){
          i = this.ignorarPaginasBot(i);
					for(let pagina = 1; pagina <= lista[i].totalPaginas; pagina++) {
						retorno.push({
              indice: i,
							idEscrito: lista[i].idEscrito,
							pagina: pagina,
              param: lista[i].parametrosExibirArquivo.replace(/\d+$/, pagina),
						});
						if(retorno.length >= qntPaginasBop) {
							break;
						}
					}
					if(retorno.length >= qntPaginasBop) {
						break;
					}
				}
			}
			return retorno
		},
    inicializarPaginas1(paginaAtual) {
      // Retorna os indices inicio e fim
      let selectedIndex = paginaAtual - 1;
      let start, end;
      let size = this.listaPaginacao.length;

      if (size < 9) {
        start = 0;
        end = size - 1;
      } else if (selectedIndex < 4) {
        start = 0;
        end = 8;
      } else if (selectedIndex > size - 5) {
        start = size - 9;
        end = size - 1;
      } else {
        start = selectedIndex - 4;
        end = selectedIndex + 4;
      }

      return [start, end];
    },
    montarPaginas() {
      let startEnd = this.inicializarPaginas1(1);
      let paginas = [];
      console.log('startEnd', startEnd)
      for(let i = startEnd[0]; i <= startEnd[1]; i++) {
        let p = this.recuperarPaginaSelecionada(i);
        console.log(p)
        paginas.push(p)
      }
    },
    inicializarPaginas(paginaAtual) {
      //this.montarPaginas();
      //console.log('inicializarPaginas', paginaAtual)
      let paginaSelecionada = this.recuperarPaginaSelecionada(paginaAtual);
      let paginaSelecionadaIndice = this.listaPaginacao.indexOf(paginaSelecionada);
      let reduzir = this.contarAtePagina(paginaSelecionadaIndice);
      let paginaPDF = (paginaAtual-reduzir);
      let contador = 5;
      let listaLocal = [];


      //console.log('pagina do processo', paginaAtual)
      //console.log(paginaSelecionadaIndice + ' id: ' + paginaSelecionada.idEscrito + ' p: ' + paginaPDF)
      //let antes = reduzir + (paginaPDF);
      let depois = this.contarApartirDaPagina(paginaSelecionadaIndice) - (paginaPDF);
      //console.log('antes', antes)
      //console.log('depois', depois)

      let qntPaginasTop = 4 - paginaPDF;
      if(paginaPDF > 2) {
        qntPaginasTop = 0;
      }
      if(qntPaginasTop >= 2){
        if(depois > 1) {
          qntPaginasTop--;
        }
        if(depois == 0) {
          qntPaginasTop++;
        }
      }

      // top
      let paginasTop = this.retornarPaginaTop(paginaSelecionadaIndice-1, qntPaginasTop);
      for(let i = 0; i <= paginasTop.length-1; i++) {
        listaLocal.unshift(paginasTop[i]);
        contador--;
      }

      //centro
      let inicio = paginaPDF-2 <= 1 ? 1 : paginaPDF-2;
      inicio = depois == 0 ? inicio -= 2 : depois == 1 ? inicio -= 1 : inicio;
      if(inicio < 1) {
        inicio = 1;
      }
      for(let i = inicio; i <= paginaSelecionada.totalPaginas; i++) {
        listaLocal.push({
          indice: paginaSelecionadaIndice,
          idEscrito: paginaSelecionada.idEscrito,
          pagina: i,
          param: paginaSelecionada.parametrosExibirArquivo.replace(/\d+$/, i),
          centro: (paginaPDF == i) ? true : false,
        });
        contador--;
        if(contador <= 0) {
          break;
        }
      }

      //bot
      let paginasBot = this.retornarPaginaBot(paginaSelecionadaIndice+1, contador);
      for(let i = 0; i <= paginasBot.length-1; i++) {
        listaLocal.push(paginasBot[i]);
        contador--;
      }

      this.listaPDFs = listaLocal;
      //console.log('this.listaPDFs', this.listaPDFs)
      //console.log('contador', contador)
      this.carregarPDFs();
      //this.$store.state.dlgLoading = false;
    },
    carregarPDFs() {
      if(this.visualizadorSelected.value == 2) {
        for(let i = 0; i <= this.listaPDFs.length-1; i++){
          this.listaPDFs[i].pdf = this.obterPDF(this.listaPDFs[i]);
        }
      }
      this.montarPaginasPDF();
    },
    obterPDF(pdf) {
      let param = pdf.param;
      if(!param || param.length < 20 ) {
        console.log("param incorreto!")
        return
      }

      return this.spedeService
        .getArquivoExibir(param, this.usuario.nome)
        .then((res) => {
            //console.log('res ', this.listaPDFs);
            const blob = new Blob([res.data], {type: res.headers["content-type"]});
            var fileURL = window.URL.createObjectURL(blob);
            return fileURL
        }).catch((data) => {
          console.log(data)

          let reader = new FileReader();
          reader.onload = function() {
            showError(reader.result);
          }
          reader.readAsText(data.response.data);

          this.$store.state.dlgLoading = false;
          //showError("Falha ao exibir arquivo! " + data.response.data);
      });
    },
    contarAtePagina(indice) {
      let contador = 0;

      let subList = this.listaPaginacao.slice(0, indice)
      for (let p of subList) {
        contador += p.totalPaginas;
      }
      return contador;
    },
		contarApartirDaPagina(indice) {
			let contador = 0;

			let subList = this.listaPaginacao.slice(indice);
      for(let p of subList) {
        contador += p.totalPaginas;
      }
			return contador;
		},
    montarPaginasPDF() {
      //remover todos os filho
      let list = document.getElementById('holder');
      if(list) {
        while (list.hasChildNodes()) {
          list.removeChild(list.firstChild);
        }
      }

      //console.log('listaPDFs', this.listaPDFs)

      Promise.all(this.listaPDFs.map(a => a.pdf)).then(() => {
      //console.log('todos os PDF foram baixados')

        let listaTmp = [];
        this.listaPDFs.forEach(x => {
          listaTmp.push(x);
        })
        //this.$store.state.dlgLoading = false;
        this.renderPDF(listaTmp);
      })
    },
    exibeArquivo(){
      if(this.visualizadorSelected.value == 2 || this.visualizadorSelected.value == 3) {
        this.$store.state.dlgLoading = true;
        //this.selecionarPDFs(this.paginaAtual);
        this.inicializarPaginas(this.paginaAtual);
        this.selecionarArvore();
        return;
      }
      this.$store.state.dlgLoading = true;
      let param = this.paginaEscritoAtual.parametrosExibirArquivo;
      if(this.visualizadorSelected.value == 2 || this.visualizadorSelected.value == 3) {
        // exibir o documento todo
        param = this.paginaEscritoAtual.parametrosExibirArquivo.replace('temp/1/', 'temp/0/')
      }
      this.spedeService
        .getArquivoExibir(param)
        .then((res) => {
            //console.log('res ', res.data);
            const blob = new Blob([res.data], {type: res.headers["content-type"]});
            var fileURL = window.URL.createObjectURL(blob);

            if(this.visualizadorSelected.value == 1) {
              document.getElementById("frameViewEscrito").src = fileURL+"#toolbar=0&zoom=100";
            }

            this.selecionarArvore();
            this.$store.state.dlgLoading = false;
        }).catch((data) => {
          console.log(data)

          let reader = new FileReader();
          reader.onload = function() {
            showError(reader.result);
          }
          reader.readAsText(data.response.data);

          this.$store.state.dlgLoading = false;
          //showError("Falha ao exibir arquivo! " + data.response.data);
      });
    },
    getItem(elemento, proximo) {
      let indice = elemento.indice;
      let pagina = elemento.pagina;
      //let pdf = elemento.pdf;
      //console.log('elemento', elemento)
      //console.log('indice ' + indice + '-' + pagina + '-' + proximo)
      //let retorno = [];
      let item = [];

      if(proximo) {
        indice = this.ignorarPaginasBot(indice);
        item = this.listaPaginacao.at(indice);
          if(item) {
            if(pagina < item.totalPaginas) {
              let param = item.parametrosExibirArquivo.replace(/\d+$/, pagina+1);
              item = {
                indice: indice,
                idEscrito: item.idEscrito,
                pagina: pagina+1,
                totalPaginas: item.totalPaginas,
                param: param, //item.parametrosExibirArquivo,
                pdf: this.obterPDF({idEscrito: item.idEscrito, param: param}),
                negarAcessoDescricao: item.negarAcessoDescricao
              }
            } else {
              let newIndice = indice+1
              newIndice = this.ignorarPaginasBot(newIndice);
              item = this.listaPaginacao.at(newIndice);
              if(item) {
                let param = item.parametrosExibirArquivo.replace(/\d+$/, 1);
                item = {
                  indice: newIndice,
                  idEscrito: item.idEscrito,
                  pagina: 1,
                  totalPaginas: item.totalPaginas,
                  param: param,
                  pdf: this.obterPDF({idEscrito: item.idEscrito, param: param}),
                  negarAcessoDescricao: item.negarAcessoDescricao
                }
              }
            }
        }
      } else {
        // ANTERIOR
        indice = this.ignorarPaginasTop(indice);
        item = this.listaPaginacao.at(indice);
        if(item) {
          if(pagina > 1) {
            let param = item.parametrosExibirArquivo.replace(/\d+$/, pagina-1);
            item = {
              indice: indice,
              idEscrito: item.idEscrito,
              pagina: pagina-1,
              totalPaginas: item.totalPaginas,
              param: param, //item.parametrosExibirArquivo,
              pdf: this.obterPDF({idEscrito: item.idEscrito, param: param}),
              negarAcessoDescricao: item.negarAcessoDescricao
            }
          } else {
            let newIndice = indice-1;
            newIndice = this.ignorarPaginasTop(newIndice);
            if(newIndice < 0)
              return
            item = this.listaPaginacao.at(newIndice);
            if(item) {
              let param = item.parametrosExibirArquivo.replace(/\d+$/, item.totalPaginas);//
              item = {
                indice: newIndice,
                idEscrito: item.idEscrito,
                pagina: item.totalPaginas,
                totalPaginas: item.totalPaginas,
                param: param, //item.parametrosExibirArquivo,
                pdf: this.obterPDF({idEscrito: item.idEscrito, param: param}),
                negarAcessoDescricao: item.negarAcessoDescricao
              }
            }
          }
        }
      }

      //console.log('getItem', item);
      return item;
    },
    novaPaginaPDF(item, last) {
      //let pdfjsLib = window['pdfjs-dist/build/pdf'];

      item.pdf.then(url => {
        let loadingTask = this.pdfjsLib.getDocument(url);
        loadingTask.promise.then((pdfDoc) => {
          //console.log('novaPaginaPDF - PDF loaded');
          let pag = item.pagina
          if(this.visualizadorSelected.value == 2) {
            pag = 1;
          }
          pdfDoc.getPage(pag).then(page => this.novaPagina(page, item, last));
        });
      })
    },
    novaPagina(page, item, last) {
        //console.log('novaPagina', item)

        let duplicado = document.querySelectorAll('.canvas-wrapper[id="'+ item.idEscrito +'"]');
        if(Array.from(duplicado).some(x => x.pagina == item.pagina)) {
          //console.log('duplicado')
          this.ellipsBot = false;
          this.ellipsTop = false;
          return;
        }

        let scale = this.scale;
        let canvasContainer = document.getElementById('holder')
        var viewport = page.getViewport({scale: scale});
        var wrapper = document.createElement("div");
        wrapper.id = item.idEscrito;
        wrapper.pagina = item.pagina;
        wrapper.totalPaginas = item.totalPaginas;
        wrapper.indice = item.indice;
        wrapper.className = "canvas-wrapper";
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport
        };

        canvas.height = viewport.height;
        canvas.width = viewport.width;
        if(last) {
          wrapper.append(canvas) // prepend (first) append (last)
          canvasContainer.append(wrapper);
        } else {
          wrapper.prepend(canvas) // prepend (first) append (last)
          canvasContainer.prepend(wrapper);
        }

        //page.render(renderContext);
        let renderTask = page.render(renderContext);
        let ref = this;
        renderTask.promise.then(function () {
          //console.log('render nova pagina ok')
          return page.getTextContent();
        }).then(function(textContent) {
          //console.log('textContent', textContent)

          var textLayerDiv = document.createElement("div");
          textLayerDiv.setAttribute("class", "textLayer");
          textLayerDiv.style.left = canvas.offsetLeft + 'px';
          // textLayerDiv.style.top = canvas.offsetTop + 'px';
          // textLayerDiv.style.height = canvas.offsetHeight + 'px';
          // textLayerDiv.style.width = canvas.offsetWidth + 'px';
          wrapper.appendChild(textLayerDiv);
          ref.pdfjsLib.renderTextLayer({
              textContent,
              container: textLayerDiv,
              viewport: viewport,
              textDivs: []
          });

          let lastElement = document.querySelector('.canvas-wrapper:last-child');
          let firstElement = document.querySelector('.canvas-wrapper:first-child');

          if(last) {
            ref.observerNewPage.observe(lastElement);
            ref.observerPageAtual.observe(lastElement);
            //
            ref.observerNewPage.unobserve(firstElement);
            ref.observerPageAtual.unobserve(firstElement);
            canvasContainer.removeChild(firstElement);
            ref.ellipsBot = false;
          } else {
            ref.observerNewPage.observe(firstElement);
            ref.observerPageAtual.observe(firstElement);
            //
            ref.observerNewPage.unobserve(lastElement);
            ref.observerPageAtual.unobserve(lastElement);
            canvasContainer.removeChild(lastElement);
            ref.ellipsTop = false;
          }
        });
    },
    renderPDF(lista) {
      if(lista.length == 0) {
        this.$store.state.dlgLoading = false;
        return
      }

      let item = lista.shift();
      item.pdf.then(url => {
        let loadingTask = this.pdfjsLib.getDocument(url);
        loadingTask.promise.then((pdf) => {
          //console.log('PDF loaded', pdf);
          this.renderPages(pdf, item, lista);
        });
      })
    },
    renderPages(pdfDoc, item, lista) {
        //console.log('renderPages' + item.idEscrito + '-' + currentPage)
        let pag = item.pagina
        if(this.visualizadorSelected.value == 2) {
          pag = 1;
        }
        pdfDoc.getPage(pag).then(page => this.renderPage(page, item, lista));
    },
    renderPage(page, item, lista) {
        //console.log('renderPage', page)
        let scale = this.scale;
        let canvasContainer = document.getElementById('holder')
        var viewport = page.getViewport({scale: scale});
        var wrapper = document.createElement("div");
        wrapper.id = item.idEscrito;
        wrapper.pagina = item.pagina;
        wrapper.totalPaginas = item.totalPaginas;
        wrapper.indice = item.indice;
        wrapper.className = "canvas-wrapper";
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport
        };

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        wrapper.append(canvas) // append (last)
        canvasContainer.append(wrapper);

        //page.render(renderContext);
        let renderTask = page.render(renderContext);
        let ref = this;
        renderTask.promise.then(function () {
          //console.log('Page rendered', page);
          return page.getTextContent();
        }).then(function(textContent) {
          //console.log('textContent', textContent)

          var textLayerDiv = document.createElement("div");
          textLayerDiv.setAttribute("class", "textLayer");
          textLayerDiv.style.left = canvas.offsetLeft + 'px';
          // textLayerDiv.style.top = canvas.offsetTop + 'px';
          // textLayerDiv.style.height = canvas.offsetHeight + 'px';
          // textLayerDiv.style.width = canvas.offsetWidth + 'px';
          wrapper.appendChild(textLayerDiv);
          ref.pdfjsLib.renderTextLayer({
              textContent,
              container: textLayerDiv,
              viewport: viewport,
              textDivs: []
          });

          // scrollTo centro
          if(item.centro) {
            let last = document.querySelector('.canvas-wrapper:last-child');
            last.scrollIntoView({behavior: "smooth", block: "start", inline: "center"})
          }

          if(lista.length > 0) {
            ref.renderPDF(lista)
          } else {
            //console.log('lista vazia')
            // iniciar observer

            // Aguardar os 5 itens carregar antes de iniciar os Observers
            let myInterval = setInterval(function() {
              let canvas = document.querySelectorAll('.canvas-wrapper')
              if(canvas.length >= ref.listaPDFs.length) {
                ref.$store.state.dlgLoading = false;
                const target = document.querySelectorAll('.canvas-wrapper');
                target.forEach(t => ref.observerPageAtual.observe(t));
                target.forEach(t => ref.observerNewPage.observe(t));
                clearInterval(myInterval);
              }
            }, 1000);
          }
        });
    },
    paginaAtualPDF(paginaSelecionada){
      let contador = 0;
      let paginaIndice = 0;
      //console.log('paginaSelecionada', paginaSelecionada)
      //console.log('this.listaPaginacao', this.listaPaginacao)
      for (let p of this.listaPaginacao) {
        if((contador + p.totalPaginas) >= paginaSelecionada && p.totalPaginas > 1){
          paginaIndice = paginaSelecionada - contador;
          contador += paginaIndice;
          // Ajustar parametrosExibirArquivo com a página correta
          break;
        } else {
          contador += p.totalPaginas;
        }
        if(contador == paginaSelecionada) {
          break;
        }
      }
      return contador;
    },
    loadPDFJS(fileURL){
      //let pdfjsLib = window['pdfjs-dist/build/pdf'];

      // The workerSrc property shall be specified.
      //pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsLib.workerSrc;

      // Using DocumentInitParameters object to load binary data.
      let loadingTask = this.pdfjsLib.getDocument(fileURL);
      loadingTask.promise.then(function(pdf) {
        console.log('PDF loaded');

        // Fetch the first page
        let pageNumber = 1;
        pdf.getPage(pageNumber).then(function(page) {
          console.log('Page loaded');

          let scale = this.scale;
          let viewport = page.getViewport({scale: scale});

          // Prepare canvas using PDF page dimensions
          let canvas = document.getElementById('the-canvas');
          let context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          // Render PDF page into canvas context
          let renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          let renderTask = page.render(renderContext);
          renderTask.promise.then(function () {
            console.log('Page rendered');
          });
        });
      }, function (reason) {
        // PDF loading error
        console.log('reason',reason);
      });
    },
    selecionarArvore() {
      let treeItem = this.selectedTreeItem;
      this.selectedTreeItem = {};
      if(this.paginaEscritoAtual.node) {
        this.hierarquiaEscritoAtual = this.paginaEscritoAtual.node.data;
        this.selectedTreeItem[this.paginaEscritoAtual.node.key] = true;
      } else {
        this.selectedTreeItem[this.listaPaginacao[this.paginaEscritoAtual.hierarquiaEscrito.paginaInicial-1].node.key] = true;
      }
      if(Object.keys(treeItem)[0] != Object.keys(this.selectedTreeItem)[0]) {
        this.scrollToSelectedTreeItem();
        this.recuperarLog();
      }
    },
		visualizarImagem(id, tipoArquivo){
			this.idItem = id;
			this.tipoArquivoItem = tipoArquivo;
			this.titulo = "Visualizar " + this.tipoArquivoItem.getDescricao() + this.idItem;
      if ((this.idItem > 0) && (this.tipoArquivoItem != "NAO_DEFINIDO") && (this.versaoItemDocumento > -1)){
        //url = SpedePlusUtils.obterUrlExibirArquivo(tipoArquivoItem, idItem);
      }
			this.exibirImagem = true;
		},
		visualizarOutroTipoArquivo(id, tipoArquivo) {
			this.visualizarOutroTipoArquivo2(id, tipoArquivo, 0);
		},
		visualizarOutroTipoArquivo2(id, tipoArquivo, versaoDocumento){
      this.idItem = id;
			this.tipoArquivoItem = tipoArquivo;
			this.versaoItemDocumento = versaoDocumento;
			this.titulo = "Visualizar " + tipoArquivo.descricao + this.idItem;
      /*if ((this.idItem > 0) && (this.tipoArquivoItem != "NAO_DEFINIDO") && (this.versaoItemDocumento > -1)){
          url = SpedePlusUtils.obterUrlExibirArquivo(tipoArquivoItem, idItem, true, paginaAtual, versaoItemDocumento);
      }*/
      this.spedeService
        .getArquivoExibir("/"+id+"/tipo/"+tipoArquivo.code+"/temp/1/pg/0")
        .then((res) => {
            this.outroItem = true;
            //console.log('exibeArquivo2 ', this.selectedTreeItem);
            const blob = new Blob([res.data], {type: res.headers["content-type"]});
            var fileURL = window.URL.createObjectURL(blob);
            document.getElementById("frameOutroItem").src = fileURL+"#toolbar=0";
            //document.getElementById("frameViewEscrito").title = "arquivo";
            this.hierarquiaEscritoAtual = null;
            this.selectedTreeItem = null;
      });
      this.exibirArquivo = true;
    },
    changeTabView(event){
      this.tvViewIndex = event.index;
      switch (event.index) {
        case 0:
            this.getPagina(this.paginaAtual);
            break;
      }

    },
		getFontStyle(hierarquiaEscrito){
				if (hierarquiaEscrito.semEfeito)
					return "color: green;";
				if (hierarquiaEscrito.situacaoRestauracaoDocumento=="DOCUMENTO_RESTAURADO_SUBSTITUIDO")
					return "color: blue;";
				if (hierarquiaEscrito.situacaoRestauracaoDocumento=="DOCUMENTO_COM_FALHA")
					return "color: red;";
				if (hierarquiaEscrito.situacaoRestauracaoDocumento=="DOCUMENTO_NAO_RESTAURADO")
					return "color: orange;";
        if (hierarquiaEscrito.documentoInterno)
          return "font-weight: bold;";
				return null;
		},
    expandAll() {
      for (let node of this.hierarquiaEscritoPrincipal) {
        this.expandNode(node, 2, 0);
      }
      this.chavesExpandidas = { ...this.chavesExpandidas };
    },
    expandNode(node, nivelMaximo, nivelAtual) {
      if (node.children && node.children.length) {
        this.chavesExpandidas[node.key] = true;
        nivelAtual++;
        if (nivelAtual < nivelMaximo){
          for (let child of node.children) {
            this.expandNode(child, nivelMaximo, nivelAtual);
          }
        }
      }
      let style = this.getFontStyle(node.data);
      node.style = style ? style : null;
    },
    getDescricaoEscrito() {
      if (this.escritoAtual.tipoEscrito=='PROCESSO') {
        return (this.escritoAtual.eletronicoProcesso? "Processo Eletrônico N° ": "Processo Físico N°") + this.escritoAtual.codigo;
      } else if (this.escritoAtual.tipoEscrito=='MANIFESTACAO') {
        return "Manifestação N° " + this.escritoAtual.codigo;
      } else {
        return (this.escritoAtual.tipoEscrito=="DOCUMENTO"? "Documento N° ": "Rascunho N° ") + this.escritoAtual.codigo
      }
    },
    usuarioExterno() {
      return this.usuario.sistemaOrigem != 'Spede';
    },
    mudarLayout() {
      if(!this.maximize && this.escritoAtual && this.listaPaginacao.length > 1){
        this.mainMarginLeftWidth = '29vw';
        return true
      }
      this.mainMarginLeftWidth = 0;
      return false;
    },
    scrollToSelectedTreeItem() {
      this.waitForElm('tr.p-highlight').then((/*elm*/) => {
          let element = this.$refs.tree123.$el.getElementsByClassName('p-highlight')
          //element[0].focus()
          //element[1].focus()
          element[0].scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
          //a[1].scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
      });
    },
    waitForElm(selector) {
      return new Promise(resolve => {
          if (document.querySelector(selector)) {
              return resolve(document.querySelector(selector));
          }

          const observer = new MutationObserver(() => {
              if (document.querySelector(selector)) {
                  resolve(document.querySelector(selector));
                  observer.disconnect();
              }
          });

          observer.observe(document.body, {
              childList: true,
              subtree: true
          });
      });
    },
    getModoVisualizacao() {
      return localStorage.getItem("MODO_VISUALIZACAO");
    },
    setModoVisualizacao(token) {
      localStorage.setItem("MODO_VISUALIZACAO", token);
    },
    getTipoVisualizacao() {
      if(this.tipoVisualizacao == 'PECA_INICIAL') {
        return "(Somente peças iniciais)"
      } else
      if(this.tipoVisualizacao == 'DECISORIO') {
        return "(Peças até o decisório)"
      } else
      if(this.tipoVisualizacao == 'INTERESSADO') {
        let mensagem = "(Todas as peças)"
        if(this.situacaoEscrito == 'ARQUIVADO') {
          mensagem += " - ARQUIVADO"
        }
        return mensagem;
      }
      return ""
    },
    getTipoVisualizacaoTooltip() {
      if(this.tipoVisualizacao == 'PECA_INICIAL') {
        return "Usuário não é parte do processo, visualização somente das peças iniciais."
      } else
      if(this.tipoVisualizacao == 'DECISORIO') {
        return "Usuário não é parte do processo, visualização das peças até o decisório."
      } else
      if(this.tipoVisualizacao == 'INTERESSADO') {
        let mensagem = "Usuário é parte do processo, visualização completa do processo."
        if(this.situacaoEscrito == 'ARQUIVADO') {
          mensagem = "Processo arquivado, visualização completa do processo."
        }
        return mensagem;
      }
      return ""
    },
    infoDownload(infoDownload) {
      // Remove os itens do check box - escritosSelecionados
      // onde há negarAcessoDescricao ou !permiteVisualizar
      if(!infoDownload) {
        //console.log("infoDownload", infoDownload)
        return infoDownload
      }
      let retorno = infoDownload;
      this.listaPaginacao.forEach(x => {
        if(x.idEscrito == infoDownload.id && (x.negarAcessoDescricao || !x.permiteVisualizar)) {
          retorno = null;
        }
      })
      return retorno
    }
  },
};
</script>
<style>
.hidden-accessible{ position: initial; }
/*
.p-panel .p-panel-content {
  padding:0.5em;
  border:0;
  min-height: 920px;
}
min-width: 550px;
margin-left: 540px;
*/

#holder-pai {
  overflow: hidden;
  height: 85vh;
}

#holder {
    height: 100%;
    max-height: 96%;
    overflow: auto;
}

.canvas-wrapper {
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
  position: relative;
}

.layout-menu-container {
  min-width: 29vw;
  background: #ffffff;
}
/* margin-left: 29vw; transition: margin-left 0.3s; */
.layout-wrapper.layout-static .layout-main {
    padding-top: 0px;
    background: #ffffff;
}

.layout-wrapper .layout-main .layout-content {
    padding: 0;
    transition: margin-left 0.3s;
    margin-left: 16px;
    margin-right: 16px;
}

.p-panel .p-panel-header {
  padding:0em;
}

canvas {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.loader-ellips {
  font-size: 20px; /* change size here */
  position: relative;
  width: 4em;
  height: 1em;
  margin: 10px auto;
}

.loader-ellips__dot {
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  background: #555; /* change color here */
  position: absolute;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader-ellips__dot:nth-child(1),
.loader-ellips__dot:nth-child(2) {
  left: 0;
}
.loader-ellips__dot:nth-child(3) { left: 1.5em; }
.loader-ellips__dot:nth-child(4) { left: 3em; }

@keyframes reveal {
  from { transform: scale(0.001); }
  to { transform: scale(1); }
}

@keyframes slide {
  to { transform: translateX(1.5em) }
}

.loader-ellips__dot:nth-child(1) {
  animation-name: reveal;
}

.loader-ellips__dot:nth-child(2),
.loader-ellips__dot:nth-child(3) {
  animation-name: slide;
}

.loader-ellips__dot:nth-child(4) {
  animation-name: reveal;
  animation-direction: reverse;
}

/* .p-panel-header {
  padding: 0rem !important;
} */
</style>